<template>
  <v-card class="my-4" elevation="4">
    <v-card-title><v-icon class="mr-2">mdi-cog</v-icon> Feature Flags</v-card-title>
    <v-card-text class="mx-2 py-0">
      <v-row class="mx-0">
        <v-col cols="3">
          <tooltip-switch
            class-props="mt-1"
            :editable="editable_buttons.enable_full_screen_splash"
            :right="true"
            :value.sync="flags.enable_full_screen_splash"
            :label="flags.enable_full_screen_splash ? 'Splash em Tela Cheia Ativo' : 'Splash em Tela Cheia Inativo'"
            :tip="appFlagTip['enable_full_screen_splash']"
          />
        </v-col>
        <v-col cols="3">
          <tooltip-switch
            class-props="mt-1"
            :editable="editable_buttons.enable_expanded_banner"
            :right="true"
            :value.sync="flags.enable_expanded_banner"
            :label="flags.enable_expanded_banner ? 'Banner Expandido Ativo' : 'Banner Expandido Inativo'"
            :tip="appFlagTip['enable_expanded_banner']"
          />
        </v-col>
        <v-col cols="3">
          <tooltip-switch
            class-props="mt-1"
            :editable="editable_buttons.enable_bottom_menu_icon"
            :right="true"
            :value.sync="flags.enable_bottom_menu_icon"
            :label="flags.enable_bottom_menu_icon ? 'Botão Menu Inferior Ativo' : 'Botão Menu Inferior Inativo'"
            :tip="appFlagTip['enable_bottom_menu_icon']"
          />
        </v-col>
        <v-col cols="3">
          <tooltip-switch
            class-props="mt-1"
            :editable="editable_buttons.enable_expanded_logo_home"
            :right="true"
            :value.sync="flags.enable_expanded_logo_home"
            :label="flags.enable_expanded_logo_home ? 'Logo Expandido Home Ativo' : 'Logo Expandido Home Inativo'"
            :tip="appFlagTip['enable_expanded_logo_home']"
          />
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col cols="3">
          <tooltip-switch
            class-props="mt-1"
            :editable="editable_buttons.enable_colorful_register_screen"
            :right="true"
            :value.sync="flags.enable_colorful_register_screen"
            :label="flags.enable_colorful_register_screen ? 'Tela de Registro Colorida Ativa' : 'Tela de Registro Colorida Inativa'"
            :tip="appFlagTip['enable_colorful_register_screen']"
          />
        </v-col>
        <v-col cols="3">
          <tooltip-switch
            class-props="mt-1"
            :editable="editable_buttons.enable_header_gradient"
            :right="true"
            :value.sync="flags.enable_header_gradient"
            :label="flags.enable_header_gradient ? 'Gradiente de Cabeçalho Ativo' : 'Gradiente de Cabeçalho Inativo'"
            :tip="appFlagTip['enable_header_gradient']"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pl-0">
      <v-spacer />
      <v-btn color="primary" :disabled="loading" @click="dialog = true"><v-icon class="mr-1">sync</v-icon> Atualizar Feature Flags</v-btn>
    </v-card-actions>

    <confirmation-dialog :dialog.sync="dialog" :text="textDialog" @proceed="updateFlags" @cancel="closeDialog" />
  </v-card>
</template>

<script>
import { MUTATION_UPDATE_APP_FEATURE_FLAGS } from '@/modules/accounts/graphql'

export default {
  components: {
    TooltipSwitch: () => import('@/components/base/TooltipSwitch'),
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    app: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: false,
    dialog: false,
    flags: {
      enable_full_screen_splash: false,
      enable_expanded_banner: false,
      enable_bottom_menu_icon: false,
      enable_expanded_logo_home: false,
      enable_colorful_register_screen: false,
      enable_header_gradient: false
    },
    appFlagTip: {
      enable_full_screen_splash: 'Habilita a exibição do splash em tela cheia. Deve ser definido o asset FULL_SCREEN_SPLASH na aba Assets.',
      enable_expanded_banner: 'Habilita a exibição do banner expandido, permitindo a exibição de um banner maior',
      enable_bottom_menu_icon: 'Habilita a exibição da logo como botão no clube. Deve ser definido o asset ROUNDED_LOGO_IMAGE na aba Assets.',
      enable_expanded_logo_home: 'Habilita a exibição do logo expandido na tela inicial, permitindo a exibição de uma logo maior',
      enable_colorful_register_screen: 'Habilita a exibição da tela de cadastro com fundo colorido.',
      enable_header_gradient:
        'Habilita a exibição do gradiente nos cabeçalhos. Utiliza as cores light e dark para fazer um efeito de gradiente nos headers onde a logo é exibida'
    },
    textDialog: 'Tem certeza que deseja atualizar as flags?',
    editable_buttons: {
      enable_full_screen_splash: true,
      enable_expanded_banner: true,
      enable_bottom_menu_icon: true,
      enable_expanded_logo_home: true,
      enable_colorful_register_screen: true,
      enable_header_gradient: true
    }
  }),
  mounted() {
    if (this.app) this.setValues()
  },
  methods: {
    flagsValidations() {
      if (!this.app?.channels?.app?.build?.assets?.full_screen_splash) {
        this.editable_buttons.enable_full_screen_splash = false
        this.flags.enable_full_screen_splash = false
      }
      if (!this.app?.channels?.app?.build?.assets?.rounded_logo_image) {
        this.editable_buttons.enable_bottom_menu_icon = false
        this.flags.enable_bottom_menu_icon = false
      }
    },
    setValues() {
      // now this field is can be null, this validation is necessary to avoid errors
      if (!this.app?.channels?.app?.build?.enable_full_screen_splash) this.flags.enable_full_screen_splash = false
      else this.flags.enable_full_screen_splash = this.app?.channels?.app?.build?.enable_full_screen_splash
      this.flags.enable_expanded_banner = this.app?.modules?.enable_expanded_banner
      this.flags.enable_bottom_menu_icon = this.app?.channels?.consumer_app?.enable_bottom_menu_icon
      this.flags.enable_expanded_logo_home = this.app?.channels?.consumer_app?.enable_expanded_logo_home
      this.flags.enable_colorful_register_screen = this.app?.channels?.consumer_app?.enable_colorful_register_screen
      this.flags.enable_header_gradient = this.app?.channels?.consumer_app?.enable_header_gradient

      this.flagsValidations()
    },
    closeDialog() {
      this.dialog = false
      this.setValues()
    },
    async updateFlags() {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_APP_FEATURE_FLAGS,
          variables: {
            account_id: this.accountId,
            flags: this.flags
          }
        })
        this.$snackbar({ message: 'Flag(s) atualizada(s) com sucesso', snackbarColor: '#2E7D32' })
        this.$emit('refresh')
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao atualizar flag(s)', snackbarColor: '#F44336' })
      }
      this.loading = false
    }
  }
}
</script>
